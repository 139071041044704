import "./main.App.css";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./pages/middleware/ProtectedRoute";
const Home = React.lazy(() => import("./pages/Home"));
const Login = React.lazy(() => import("./pages/Login"));
const CalendarCRUD = React.lazy(() => import("./pages/CalendarCRUD"));
const BoardMembersCRUD = React.lazy(() => import("./pages/BoardMembersCRUD"));
const AttractionCRUD = React.lazy(() => import("./pages/AttractionCRUD"));
const GalleryCRUD = React.lazy(() => import("./pages/GalleryCRUD"));
const NeighborhoodWatchCRUD = React.lazy(() =>
  import("./pages/NeighborhoodWatchCRUD")
);
const UtilityCRUD = React.lazy(() => import("./pages/UtilityCRUD"));
const NotFound = React.lazy(() => import("./pages/NotFound"));

function App() {
  return (
    <div className="App">
      <Router>
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/admin" component={Login} />
            <ProtectedRoute
              exact
              path="/admin/calendar"
              component={CalendarCRUD}
            />
            <ProtectedRoute
              exact
              path="/admin/neighborhoodwatch"
              component={NeighborhoodWatchCRUD}
            />
            <ProtectedRoute
              exact
              path="/admin/boardmembers"
              component={BoardMembersCRUD}
            />
            <ProtectedRoute
              exact
              path="/admin/attraction"
              component={AttractionCRUD}
            />
            <ProtectedRoute
              exact
              path="/admin/gallery"
              component={GalleryCRUD}
            />
            <ProtectedRoute
              exact
              path="/admin/utility"
              component={UtilityCRUD}
            />
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}
export default App;

//            Routes only meant for testing images when in development
//           // import Image from "./pages/Image";
//           <Route
//             exact
//             path="/public/calendar/:id"
//             render={(props) => <Image {...props} url={"/public/calendar/"} />}
//           />
//           <Route
//             exact
//             path="/public/attraction/:id"
//             render={(props) => <Image {...props} url={"/public/attraction/"} />}
//           />
//           <Route
//             exact
//             path="/public/gallery/:id"
//             render={(props) => <Image {...props} url={"/public/gallery/"} />}
//           />
