import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  useEffect(() => {
    let isCancelled = false;
    async function checkTokenOnServerSide() {
      try {
        const response = await fetch("/api/admin/checktoken");
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson ? await response.json() : response;
        if (data.fail || !response.ok) {
          throw data;
        }
        if (!isCancelled) {
          setIsAuth(true);
          setIsLoading(false);
        }
      } catch (err) {
        if (!isCancelled) {
          console.log(err);
          setIsAuth(false);
          setIsLoading(false);
        }
      }
    }
    checkTokenOnServerSide();
    return () => {
      isCancelled = true;
    };
  }, [location]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoading ? (
          <h1>Validerar dig</h1>
        ) : isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/admin", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
